import VueI18n, { TranslateResult } from 'vue-i18n';
import translations from '../i18n/translations';
import CookieService from '@/services/cookieService';
import apiClient, { setRequestAcceptLanguage } from '@/services/apiClient';
import { SupportedLanguage } from '@/types/supportedLanguage';
import { LanguageSettings } from '@/types/languageSettings';

export class LanguageService {

  // Note: Ensure the locales entered here in this class are all lowercase
  private readonly supportedLanguages: SupportedLanguage[] = [
    { code: 'en-us', name: 'English (US)', flagIconFilename: 'us.svg' },
    { code: 'en-gb', name: 'English (GB)', flagIconFilename: 'gb.svg' },
    { code: 'cy', name: 'Cymraeg', flagIconFilename: 'cy.svg' },
    { code: 'vi-vn', name: 'Test Language', flagIconFilename: 'vn.svg' }
  ];

  private languageSettings: LanguageSettings | null = null;
  private availableLanguages: SupportedLanguage[] | null = null;

  /**
   * Defines the languages in the main webpack bundle (i.e. those not lazy loaded)
   * @private
   */
  private readonly builtInLocales: string[] = ['en-us', 'en-gb', 'vi-vn'];
  private readonly fallbackLocale: string = 'en-gb';

  private i18n!: VueI18n;

  constructor() {
    if (process.env.NODE_ENV === 'production') {
      this.supportedLanguages = this.supportedLanguages.filter(x => x.code !== 'vi-vn');
    }
  }

  async setup(): Promise<VueI18n> {
    this.i18n = new VueI18n({
      locale: this.fallbackLocale,
      fallbackLocale: this.fallbackLocale,
      messages: translations,
      silentTranslationWarn: process.env.NODE_ENV === 'production'
    });

    // Get the language settings from the server (if they haven't already been retrieved)
    if (this.languageSettings === null) {
      this.languageSettings = await this.getLanguageSettings();
      this.availableLanguages = this.getAvailableLanguages(this.languageSettings.availableLanguageCodes);
    }

    const preferredLocale = CookieService.getLanguageCode() ?? this.languageSettings.defaultLanguageCode;
    await this.changeLocale(preferredLocale);

    return this.i18n;

  }

  get currentLocale(): string {
    return this.i18n.locale;
  }

  get availableLanguagesList(): SupportedLanguage[] {
    return this.availableLanguages ?? this.supportedLanguages;
  }

  /**
   * Helper method for retrieving translated text in code.
   * vue-i18n.t() returns TranslateResult type rather than string that makes things a bit complicated as we want string.
   * @param {string} key The key for the resource string
   * @param {any[] | object} values Placeholder replacement values
   * @return {string} The translated string
   */
  t(key: string, values?: any[] | { [key: string]: any }): string {
    const result: TranslateResult = this.i18n.t(key, values);

    if (typeof result === 'string') {
      return result;
    }

    // We didn't get a string, could be missing entry or incorrectly provided key (either way it's a problem)
    throw new TypeError(`Cannot translate the value of keypath '${key}'. Use the value of keypath as default.`);
  }

  changeLocale(preferredLocale: string): Promise<void> {
    const supportedLocale = this.getSupportedLocale(preferredLocale);

    // If the locale is the current locale then do nothing
    if (supportedLocale === this.i18n.locale) {
      return Promise.resolve();
    }

    const getLocaleMessageObjectPromise= new Promise<void>(resolve => {
      if (this.builtInLocales.includes(supportedLocale)) {
        resolve();
      }

      // Load the supported locale messages from the server
      return this.asyncLoadLocaleFile(supportedLocale)
        .then(messageObject => {
          this.i18n.setLocaleMessage(supportedLocale, messageObject.default);
          resolve();
        });
    });

    return getLocaleMessageObjectPromise
      .then(() => {
        this.i18n.locale = supportedLocale;
        CookieService.setLanguageCode(supportedLocale);
        setRequestAcceptLanguage(supportedLocale);
        document.documentElement.lang = supportedLocale;
      });
  }

  /**
   * Gets a locale that is supported either because the full language code & region code are supported,
   * just language code is supported or the fallback locale is returned.
   * @param {string} preferredLocale The locale the requested to be used
   * @return {string} The supported locale
   */
  private getSupportedLocale(preferredLocale: string): string {

    // Check if locale with language code and region code is supported
    if (this.isLocaleSupported(preferredLocale)) {
      return preferredLocale;
    }

    // Check if just the language code is supported
    const localeLanguageCode = preferredLocale.split('-')[0];

    if (this.isLocaleSupported(localeLanguageCode)) {
      return localeLanguageCode;
    }

    const message = `The locale of '${preferredLocale}' is not supported.
    Reverting to fallback '${this.fallbackLocale}'.`;

    console.warn(message);
    return this.fallbackLocale;
  }

  /**
   * Gets the default language code and the available language codes from the server. If the API
   * call fails for any reason, default values are returned.
   * @private
   * @return {Promise<LanguageSettings>} A Promise for the language settings
   */
  private getLanguageSettings(): Promise<LanguageSettings> {
    return apiClient.get('settings/languageSettings')
      .then(response => {
        const languageSettings: LanguageSettings = {
          defaultLanguageCode: response.data.defaultLanguageCode.toLowerCase(),
          availableLanguageCodes: response.data.availableLanguageCodes.map((x: string) => x.toLowerCase())
        };
        return languageSettings;
      })
      .catch(error => {
        console.warn('Unable to retrieve language settings from the server so using defaults', error);
        const defaultLanguageSettings: LanguageSettings = {
          defaultLanguageCode: this.supportedLanguages[0].code,
          availableLanguageCodes: []
        };
        return defaultLanguageSettings;
      });
  }

  /**
   * Gets the language codes that are available for a user to select from based on the information
   * returned from the API call to the server.
   * @param {string[]} availableLanguageCodes The list of allowed language codes
   * @return {SupportedLanguage[]} The supported languages
   */
  private getAvailableLanguages(availableLanguageCodes: string[]): SupportedLanguage[] {
    // If the list of available languages is empty then the list is not being controlled via the
    // system setting "AvailableLanguageCodes" so allow access to all supported languages.
    if (!availableLanguageCodes?.length) {
      return this.supportedLanguages;
    }

    return this.supportedLanguages.filter(available => availableLanguageCodes.includes(available.code));
  }

  private isLocaleSupported(locale: string): boolean {
    return this.supportedLanguages.map(x => x.code).includes(locale);
  }

  private asyncLoadLocaleFile(locale: string): Promise<any> {
    return import(/* webpackChunkName: "locale-[request]" */ `@/i18n/${locale}.json`);
  }
}

const languageService = new LanguageService();

export default languageService;
